import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        type: null,
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
        mutateType(state, token) {
            state.type = token
        },
    },
    actions: {
        //Auth
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'admin-login', {...user})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            commit('mutateType', 'pawn1')
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Expire Login Date Check
        async expireCheck({commit}) {
            const result = (await axios.get(env.apiUrl + 'login-date-expire', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dashboardMortgageAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'calc-mortgage', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async calcCompletedAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'calc-completed', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async calcPendingAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'calc-pending', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async generateCode({commit}) {
            const result = (await axios.get(env.apiUrl + 'generate-code', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard Cloth
        async dashboardClothAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'cloth-dashboard', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dashboardClothMortgageAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'cloth-calc-mortgage', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dashboardCalcCompletedAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'cloth-calc-completed', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dashboardCalcPendingAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'cloth-calc-pending', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Account
        async accountListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async accountFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async accountStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Shop
        async shopListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async shopFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async shopStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async shopUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async shopDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Item
        async itemListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async itemFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async itemStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async itemUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async itemDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Item Cloth
        async itemClothListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-cloth-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async itemClothFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-cloth-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async itemClothStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-cloth-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async itemClothUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-cloth-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async itemClothDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'item-cloth-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Interest Cloth
        async interestClothListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async interestClothFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async interestClothStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async interestClothUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async interestClothDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async interestClothStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'interest-cloth-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Mortgage
        async mortgageListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageCaclCountAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-calc-count', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageExcelListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-excel-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageSearchAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-search', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageReferenceAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-reference', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Mortgage Cloth
        async mortgageClothListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothCaclCountAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-calc-count', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageClothExcelListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-excel-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothSearchAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-search', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageClothReferenceAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-reference', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async mortgageClothStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async mortgageClothDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'mortgage-cloth-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Report
        async dailyReportListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'daily-report-list', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async dailyReportSaveAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'daily-report-save', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async dailyReportListForClothAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'daily-report-list-for-cloth', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async dailyReportSaveForClothAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'daily-report-save-for-cloth', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        //Excel Export
        async offlineExcelImport({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'offline-excel-import', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async offlineExcelImportCloth({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'offline-excel-import-cloth', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },

    },
    modules: {
    }
})
