<template>
    <div id="app">
        <transition>
            <div class="welcome" v-if="welcoming"></div>
        </transition>
        <router-view />
    </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'

export default {
    name: 'app',
    data() {
        return {
            welcoming: true,
        }
    },
    watch: {
        '$route' (to, from) {
            this.checkToken()
            this.checkExpireDate()
        }
    },
    methods: {
        ...mapMutations([
            'mutateToken',
            'mutateUser',
        ]),
        ...mapActions({
            expireCheck: 'expireCheck',
        }),
        checkToken() {
            const token = window.localStorage.getItem('token')
            const user = window.localStorage.getItem('user')
            const data = JSON.parse(token)
            const userData = JSON.parse(user)
            const path = this.$route.path
            if (data) {
                this.mutateToken(data)
                this.mutateUser(userData)
            } else {
                if (this.$route.path !== '/') this.$router.replace('/login').catch(()=>{})
            }
        },
        async checkExpireDate() {
            await this.expireCheck().then(res => {
                if (res.data === false) {
                    window.localStorage.setItem('token', null)
                    this.$router.replace('/login').catch(()=>{})
                }
            }).catch()
        }
    },
    async mounted() {
        const timeout = setTimeout(() => {
            this.welcoming = false
            clearTimeout(timeout)
        }, 800)
        this.checkToken()
        this.checkExpireDate()

    }
}
</script>

<style lang="scss">
	@import "https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css";
	@import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
</style>
