import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [
		{
		path: '/login',
		name: 'login',
		component: Login
		},
		{
			path: '/admin',
			name: 'admin',
			component: Admin,
			children: [{
				path: '/dashboard',
				name: 'dashboard',
				component: () => import('../views/Dashboard'),
			},
			{
				path: '/dashboard-cloth',
				name: 'dashboard-cloth',
				component: () => import('../views/DashboardCloth'),
			},
			{
				path: '/account-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
				},
				name: 'account-list',
				component: () => import('../views/account/accountList'),
			},
			{
				path: '/account-create',
				name: 'account-create',
				component: () => import('../views/account/accountCreate'),
			},
			{
				path: '/account-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
					id: '',
				},
				name: 'account-edit',
				component: () => import('../views/account/accountEdit'),
			},
			{
				path: '/shop-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
				},
				name: 'shop-list',
				component: () => import('../views/shop/shopList'),
			},
			{
				path: '/shop-create',
				name: 'shop-create',
				component: () => import('../views/shop/shopCreate'),
			},
			{
				path: '/shop-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
					id: '',
				},
				name: 'shop-edit',
				component: () => import('../views/shop/shopEdit'),
			},
			{
				path: '/item-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
				},
				name: 'item-list',
				component: () => import('../views/item/itemList'),
			},
			{
				path: '/item-create',
				name: 'item-create',
				component: () => import('../views/item/itemCreate'),
			},
			{
				path: '/item-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
					id: '',
				},
				name: 'item-edit',
				component: () => import('../views/item/itemEdit'),
			},
			{
				path: '/mortgage-create',
				name: 'mortgage-create',
				component: () => import('../views/mortgage/mortgageCreate'),
			},
			{
				path: '/mortgage-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'mortgage-edit',
				component: () => import('../views/mortgage/mortgageEdit'),
			},
			{
				path: '/mortgage-search',
				name: 'mortgage-search',
				component: () => import('../views/mortgage/mortgageSearch'),
			},
			{
				path: '/mortgage-status',
				query: {
					id: '',
					type: '' 
				},
				name: 'mortgage-status',
				component: () => import('../views/mortgage/mortgageStatus'),
			},
			{
				path: '/mortgage-all-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					nofrom: '',
					noto: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					shop: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					paydate_to: '',
					expdate_from:'',
					expdate_to:'',
					otherstatus: '',
					inventoryMode: ''

				},
				name: 'mortgage-all-list',
				component: () => import('../views/mortgage/mortgageAllList'),
			},
			{
				path: '/mortgage-today',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					shop: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'mortgage-today',
				component: () => import('../views/mortgage/mortgageToday'),
			},
			{
				path: '/pay-today',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'pay-today',
				component: () => import('../views/mortgage/payToday'),
			},
			{
				path: '/mortgage-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'mortgage-list',
				component: () => import('../views/mortgage/mortgageList'),
			},
			{
				path: '/pay-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'pay-list',
				component: () => import('../views/mortgage/payList'),
			},
			{
				path: '/mortgage-processing-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					expdate_from:'',
					expdate_to:'',
					otherstatus: ''

				},
				name: 'mortgage-processing-list',
				component: () => import('../views/mortgage/mortgageProcessingList'),
			},
			{
				path: '/loss-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'loss-list',
				component: () => import('../views/mortgage/lossList'),
			},
			{
				path: '/shop-daily-report-list',
				query: {
					month: "",
					shop: "",
				},
				name: 'shop-daily-report-list',
				component: () => import('../views/report/shopDailyReportList'),
			},
			{
				path: '/daily-report-list',
				query: {
					month: "",
				},
				name: 'daily-report-list',
				component: () => import('../views/report/dailyReportList'),
			},
			{
				path: '/print-view',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'print-view',
				component: () => import('../views/print/PrintView'),
			},
			{
				path: '/print',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'print-view',
				component: () => import('../views/print/Print'),
			},
			{
				path: '/excel-export',
				name: 'excel-export',
				component: () => import('../views/excel/excelExport'),
			},
			{
				path: '/offline-upload',
				name: 'offline-upload',
				component: () => import('../views/offline/offlineUpload'),
			},
			{
				path: '/interest-cloth-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
				},
				name: 'interest-cloth-list',
				component: () => import('../views/interest/interestList'),
			},
			{
				path: '/interest-cloth-create',
				name: 'interest-cloth-create',
				component: () => import('../views/interest/interestCreate'),
			},
			{
				path: '/interest-cloth-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
					id: '',
				},
				name: 'interest-cloth-edit',
				component: () => import('../views/interest/interestEdit'),
			},
			{
				path: '/item-cloth-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
				},
				name: 'item-cloth-list',
				component: () => import('../views/itemcloth/itemList'),
			},
			{
				path: '/item-cloth-create',
				name: 'item-cloth-create',
				component: () => import('../views/itemcloth/itemCreate'),
			},
			{
				path: '/item-cloth-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					name: '',
					id: '',
				},
				name: 'item-cloth-edit',
				component: () => import('../views/itemcloth/itemEdit'),
			},
			{
				path: '/mortgage-cloth-create',
				name: 'mortgage-cloth-create',
				component: () => import('../views/mortgagecloth/mortgageCreate'),
			},
			{
				path: '/mortgage-cloth-edit',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'mortgage-cloth-edit',
				component: () => import('../views/mortgagecloth/mortgageEdit'),
			},
			{
				path: '/cloth-mortgage-search',
				name: 'cloth-mortgage-search',
				component: () => import('../views/mortgagecloth/mortgageSearch'),
			},
			{
				path: '/cloth-mortgage-status',
				query: {
					id: '',
					type: '' 
				},
				name: 'cloth-mortgage-status',
				component: () => import('../views/mortgagecloth/mortgageStatus'),
			},
			{
				path: '/mortgage-cloth-all-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					nofrom: '',
					noto: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					shop: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'',
					paydate_to: '',
					expdate_from:'',
					expdate_to:'', 
					otherstatus: '',
					inventoryMode: ''

				},
				name: 'mortgage-cloth-all-list',
				component: () => import('../views/mortgagecloth/mortgageAllList'),
			},
			{
				path: '/mortgage-cloth-today',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					shop: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'mortgage-cloth-today',
				component: () => import('../views/mortgagecloth/mortgageToday'),
			},
			{
				path: '/cloth-pay-today',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'cloth-pay-today',
				component: () => import('../views/mortgagecloth/payToday'),
			},
			{
				path: '/cloth-mortgage-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'cloth-mortgage-list',
				component: () => import('../views/mortgagecloth/mortgageList'),
			},
			{
				path: '/cloth-pay-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'cloth-pay-list',
				component: () => import('../views/mortgagecloth/payList'),
			},
			{
				path: '/cloth-mortgage-processing-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					expdate_from:'',
					expdate_to:'',
					otherstatus: ''

				},
				name: 'cloth-mortgage-processing-list',
				component: () => import('../views/mortgagecloth/mortgageProcessingList'),
			},
			{
				path: '/cloth-loss-list',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					notype: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'cloth-loss-list',
				component: () => import('../views/mortgagecloth/lossList'),
			},
			{
				path: '/shop-daily-report-list-for-cloth',
				query: {
					month: "",
					shop: "",
				},
				name: 'shop-daily-report-list-for-cloth',
				component: () => import('../views/report/shopDailyReportListForCloth'),
			},
			{
				path: '/daily-report-list-for-cloth',
				query: {
					month: "",
				},
				name: 'daily-report-list-for-cloth',
				component: () => import('../views/report/dailyReportListForCloth'),
			},
			{
				path: '/cloth-print',
				query: {
					page: 1,
					reverse: '',
					sort: '',
					liststatus: '',
					no: '',
					name: '',
					address: '',
					loan: '',
					item: '',
					startdate: '',
					enddate: '',
					status: '',
					referencestatus: '',
					nodate: '', 
					paydate:'', 
					otherstatus: ''

				},
				name: 'cloth-print-view',
				component: () => import('../views/print/ClothPrint'),
			},
			{
				path: '/cloth-excel-export',
				name: 'cloth-excel-export',
				component: () => import('../views/excel/clothExcelExport'),
			},
			{
				path: '/cloth-offline-upload',
				name: 'cloth-offline-upload',
				component: () => import('../views/offline/clothOfflineUpload'),
			},
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router