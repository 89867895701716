<template>
    <div id="app">
        <div class="page-wrapper default-theme sidebar-bg bg1 toggled">
            <nav id="sidebar" :class="['sidebar-wrapper', {'nav_open': this.open, 'nav_close': this.close},]">
                <div :class="['sidebar-content', {'bgStyle': type == 'pawn2'}]">
                    <!-- sidebar-brand  -->
                    <!-- <div class="sidebar-item sidebar-brand">
                        <a href="#"><img class="img-responsive img-rounded" style="width: 80%;"
                                src="../assets/logo.png"></a>
                    </div> -->
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header d-flex flex-nowrap">
                        <div class="user-pic" >
                            <!-- <img class="img-responsive img-rounded" style="width: 80%;"
                                src="../assets/logo.png"> -->
                            <img class="img-responsive img-rounded" style="background: #ffffff;" src="../assets/logo.jpg" alt="User picture">
                        </div>
                        <div class="user-info">
                            <span class="user-name">{{name}}</span>
                            <span class="user-role text-capitalize">{{role}} (
                                <span class="active" v-if="type == 'pawn1'">ရွှေထည်</span>
                                <span class="active" v-if="type == 'pawn2'">အထည်</span>
                            )
                            </span>
                        </div>
                    </div>
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <!-- Gold -->
                            <li style="margin-top: 12px;" v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'dashboard'}]" to="/dashboard" >
                                    <i class="fas fa-tachometer-alt"></i>
                                    <span class="menu-text">ပင်မစာမျက်နှာ</span>
                                </router-link>
                            </li>
                            <!-- Cloth -->
                            <li style="margin-top: 12px;" v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'dashboard-cloth'}]" to="/dashboard-cloth" >
                                    <i class="fas fa-tachometer-alt"></i>
                                    <span class="menu-text">ပင်မစာမျက်နှာ</span>
                                </router-link>
                            </li>
                            <!-- Common -->
                            <li v-if="this.$role == 'admin'">
                                <router-link :class="[{active: url === 'account-list' || url === 'account-create' ||  url === 'account-edit'}]" :to="{ path: 'account-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-user"></i>
                                    <span class="menu-text">အကောင့်</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link  :class="[{active: url === 'shop-list' ||  url === 'shop-create' ||  url === 'shop-edit'}]" v-if="this.$role == 'admin'" :to="{ path: 'shop-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-store"></i>
                                    <span class="menu-text">ဆိုင်ခွဲ</span>
                                </router-link>
                            </li>
                            <!-- Gold -->
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'item-list' ||  url === 'item-create' ||  url === 'item-edit'}]" :to="{ path: 'item-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-gem"></i>
                                    <span class="menu-text">ပစ္စည်းအမျိုးအစား</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-create'}]" to="/mortgage-create">
                                    <i class="fas fa-cash-register"></i>
                                    <span class="menu-text">အပေါင်လက်ခံ</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-search' ||  url === 'mortgage-status'}]" to="/mortgage-search">
                                    <i class="fas fa-handshake"></i>
                                    <span class="menu-text">အပေါင်ပြန်ရွေး</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-today'}]" :to="{ path: 'mortgage-today', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'todaymortgage', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">ယနေ့ အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'pay-today'}]" :to="{ path: 'pay-today', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'todaypay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">ယနေ့ အရွေးစာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-list'}]" :to="{ path: 'mortgage-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'mortgage', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'pay-list'}]" :to="{ path: 'pay-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'pay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အရွေးစာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-processing-list'}]" :to="{ path: 'mortgage-processing-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'processing', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', expdate_from:'', expdate_to:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">လက်ကျန် အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'loss-list'}]" :to="{ path: 'loss-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'pay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အပေါင်ဆုံးစာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'mortgage-all-list'}]" :to="{ path: 'mortgage-all-list', query: { page: 1, reverse: 'desc', sort: 'created_at', liststatus: 'all', notype: '', nofrom: '', noto: '', no: '', name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', paydate_to:'', expdate_from:'', expdate_to:'', otherstatus: '', inventoryMode: ''}}">
                                   <i class="fas fa-receipt"></i>
                                    <span class="menu-text">စာရင်း အားလုံးပေါင်း</span>
                                </router-link>
                            </li>
                            <!-- Cloth -->
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'item-cloth-list' ||  url === 'item-cloth-create' ||  url === 'item-cloth-edit'}]" :to="{ path: 'item-cloth-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-bicycle"></i>
                                    <span class="menu-text">ပစ္စည်းအမျိုးအစား</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'interest-cloth-list' ||  url === 'interest-cloth-create' ||  url === 'interest-cloth-edit'}]" :to="{ path: 'interest-cloth-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-hourglass-start"></i>
                                    <span class="menu-text">အပေါင်သက်တမ်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'mortgage-cloth-create'}]" to="/mortgage-cloth-create">
                                    <i class="fas fa-cash-register"></i>
                                    <span class="menu-text">အပေါင်လက်ခံ</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-mortgage-search' ||  url === 'cloth-mortgage-status'}]" to="/cloth-mortgage-search">
                                    <i class="fas fa-handshake"></i>
                                    <span class="menu-text">အပေါင်ပြန်ရွေး</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'mortgage-cloth-today'}]" :to="{ path: 'mortgage-cloth-today', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'todaymortgage', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">ယနေ့ အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-pay-today'}]" :to="{ path: 'cloth-pay-today', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'todaypay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">ယနေ့ အရွေးစာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-mortgage-list'}]" :to="{ path: 'cloth-mortgage-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'mortgage', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-pay-list'}]" :to="{ path: 'cloth-pay-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'pay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အရွေးစာရင်း</span>
                                </router-link>
                            </li>
                             <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-mortgage-processing-list'}]" :to="{ path: 'cloth-mortgage-processing-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'processing', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', expdate_from:'', expdate_to:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">လက်ကျန် အပေါင်စာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-loss-list'}]" :to="{ path: 'cloth-loss-list', query: { page: 1, reverse: 'desc', sort: 'created_at',  liststatus: 'pay', notype: '', no: '',name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', otherstatus: ''}}">
                                    <i class="fas fa-money-check"></i>
                                    <span class="menu-text">အပေါင်ဆုံးစာရင်း</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'mortgage-cloth-all-list'}]" :to="{ path: 'mortgage-cloth-all-list', query: { page: 1, reverse: 'desc', sort: 'created_at', liststatus: 'all', notype: '', nofrom: '', noto: '', no: '', name: '', address: '', loan: '', shop: '', item: '', 
                                startdate: '',enddate: '', status: '', referencestatus: '', nodate: '', paydate:'', paydate_to:'', expdate_from:'', expdate_to:'', otherstatus: '', inventoryMode: ''}}">
                                   <i class="fas fa-receipt"></i>
                                    <span class="menu-text">စာရင်း အားလုံးပေါင်း</span>
                                </router-link>
                            </li>
                            <!-- Gold -->
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'shop-daily-report-list'}]" :to="{ path: 'shop-daily-report-list', query: { month: month, shop: ''}}">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span class="menu-text">ဆိုင်ခွဲ နေ့စဥ်စာရင်း</span>
                                </router-link>
                            </li>
                            <!-- <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'daily-report-list'}]" :to="{ path: 'daily-report-list', query: { month: month}}">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span class="menu-text">နေ့စဥ်စာရင်း အားလုံးပေါင်း</span>
                                </router-link>
                            </li> -->
                            <!-- Cloth -->
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'shop-daily-report-list-for-cloth'}]" :to="{ path: 'shop-daily-report-list-for-cloth', query: { month: month, shop: ''}}">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span class="menu-text">ဆိုင်ခွဲ နေ့စဥ်စာရင်း</span>
                                </router-link>
                            </li>
                            <!-- <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'daily-report-list-for-cloth'}]" :to="{ path: 'daily-report-list-for-cloth', query: { month: month}}">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span class="menu-text">နေ့စဥ်စာရင်း အားလုံးပေါင်း</span>
                                </router-link>
                            </li> -->
                            <!-- Gold -->
                            <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'excel-export'}]"  to="/excel-export">
                                    <i class="fas fa-clipboard-list"></i>
                                    <span class="menu-text">စာရင်း ကြည့်ရန်</span>
                                </router-link>
                            </li>
                             <li v-if="type == 'pawn1'">
                                <router-link :class="[{active: url === 'offline-upload'}]" to="/offline-upload">
                                    <i class="fas fa-file-excel"></i>
                                    <span class="menu-text">Excel ဖြင့်စာရင်းသွင်းရန်</span>
                                </router-link>
                            </li>
                            <!-- Cloth -->
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-excel-export'}]"  to="/cloth-excel-export">
                                    <i class="fas fa-clipboard-list"></i>
                                    <span class="menu-text">စာရင်း ကြည့်ရန်</span>
                                </router-link>
                            </li>
                            <li v-if="type == 'pawn2'">
                                <router-link :class="[{active: url === 'cloth-offline-upload'}]" to="/cloth-offline-upload">
                                    <i class="fas fa-file-excel"></i>
                                    <span class="menu-text">Excel ဖြင့်စာရင်းသွင်းရန်</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
                <!-- sidebar-footer  -->
                <div :class="['sidebar-footer', {'bgStyle': type == 'pawn2'}]">
                    <div class="dropdown">
                        <a href="#" :class="[{active: type === 'pawn1'}]" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @dblclick="changePawn1()">
                            <i class="fas fa-gem"></i>
                        </a>
                    </div>
                    <div class="dropdown">
                        <a href="#" :class="[{active: type === 'pawn2'}]" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @dblclick="changePawn2()">
                            <i class="fas fa-bicycle"></i>
                        </a>
                    </div>
                    <div>
                        <a href="#" @click="logout()">
                            <i class="fa fa-power-off"></i>
                        </a>
                    </div>
                    <div class="pinned-footer">
                        <a href="#">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="layer" @click="closeNav()" :class="{'layer_open': this.open}"></div>
            <!-- page-content  -->
            <main class="page-content pt-2">
                <label class="icon admin-menu" @click="openNav()" for="check-menu" style="margin-left: 2%;">
                    &#9776;
                </label>
                <router-view />
            </main>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        name: 'admin',
        data() {
            return {
                check: true,
                close: true,
                open: false,
                name: '',
                role: '',
                type: '',
                url: '',
                role: '',
                month: ''
            }
        },
        methods: {
            openNav: function () {
                this.open = !this.open
                if (this.check == true) {
                    this.close = false
                    this.open = true
                }
            },
            closeNav: function () {
                this.open = !this.open
                if (this.open == false) {
                    this.close = true
                    this.open = false
                }
            },
            changePawn1: function (){
                window.localStorage.setItem('type', "pawn1")
                this.type = window.localStorage.getItem('type')
                this.changeRoute()
            },
            changePawn2: function (){
                window.localStorage.setItem('type', "pawn2")
                this.type = window.localStorage.getItem('type')
                this.changeRoute()
            },
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login').catch(()=>{})
            },
            changeRoute(){
                if(this.type == "pawn1"){
                    this.$router.push('/dashboard').catch(()=>{})
                }
                if(this.type == "pawn2"){
                    this.$router.push('/dashboard-cloth').catch(()=>{})
                }
            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
            },
        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.name = user.name
            this.role = user.role
            this.type = window.localStorage.getItem('type')
            this.month = moment(new Date()).format('YYYY-MM')
            this.url = this.$route.name
        }
    }
</script>
<style lang="scss">
    @import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
    @import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
    @import "../assets/css/main.css";
    @import "../assets/css/sidebar-themes.css";

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #525965;
    }

    .bgStyle{
        background: #365471 !important;
    }

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 50px;
        right: 2%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-loss {
        position: fixed;
        bottom: 50px;
        right: 8%;
        background-color: #007bffC9;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-excel {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 117px;
        right: 2%;
        background-color: #007bff;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-save {
        position: fixed;
        bottom: 50px;
        right: 2%;
        background-color: #007bffC9;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 120px;
        right: 2%;
        background-color: #007bff;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .btn-option {
        border: none;
        background: none;
        font-size: 24px;
    }

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus {
        box-shadow: none !important;
    }

    .modal-backdrop {
        opacity: 0.5 !important;
    }

    /* Add a black background color to the top navigation */
    .topnav {
        background-color: #333;
        overflow: hidden;
    }

    /* Style the links inside the navigation bar */
    .topnav a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
    }

    /* Change the color of links on hover */
    .topnav a:hover {
        background-color: #ddd;
        color: black;
    }

    /* Add an active class to highlight the current page */
    .topnav a.active {
        background-color: #4CAF50;
        color: white;
    }

    /* Hide the link that should open and close the topnav on small screens */
    .topnav .icon {
        display: none;
    }

    // Table
    .desc {
        background-repeat: no-repeat;
        background-position: right;
        /* width: 1em; height: 1em; */
        /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
        opacity: 1;
        /* padding: 3%; */
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
    }

    .asc {
        background-repeat: no-repeat;
        background-position: right;
        /* width: 1em; height: 1em; */
        /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
        opacity: 1;
        /* padding: 3%; */
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII= ');
    }

    .table_info {
        float: left;
    }

    .paging_simple_numbers {
        float: right;
    }

    th {
        text-align: center;
    }

    td {
        text-align: center;
        white-space: nowrap;
    }

    .th-width-50 {
        width: 50px;
    }

    .th-width-100 {
        width: 100px;
    }

    .th-width-200 {
        width: 200px;
    }

    .th-width-300 {
        width: 300px;
    }
    .th-width-400 {
        width: 400px;
    }
    // Date Format Css
    input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
        color: #fff;
        position: relative;
    }

    input[type="date"]::-webkit-datetime-edit-year-field{
    position: absolute !important;
    border-left:1px solid #8c8c8c;
    padding: 2px;
    color:#000;
    left: 56px;
    }

    input[type="date"]::-webkit-datetime-edit-month-field{
    position: absolute !important;
    border-left:1px solid #8c8c8c;
    padding: 2px;
    color:#000;
    left: 26px;
    }


    input[type="date"]::-webkit-datetime-edit-day-field{
    position: absolute !important;
    color:#000;
    padding: 2px;
    left: 4px;
    }
    // 
    .active {
        color: #20c997 !important;
    }
</style>